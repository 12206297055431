<template>
  <div class="col-12 col-lg-6 c g">
    <div class="card card-body">
        <div class="col-12 col-lg-6 g c">
             اختر الطالب:
            <v-select :options="students" dir="rtl" v-model="student_" placeholer="اختر الطالب..." label="name">
            </v-select>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="callNow()">
                مناداة الان
            </button>
        </div>
    </div>
  </div>
</template>

<script>
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
export default {
    components: {
        vSelect
    },
    directives: {
        Ripple
    },
    created(){
        if(!checkPer("calls")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        $.post(api + '/user/students/list', {
            jwt: this.user.jwt,
            page: 1,
            perpage: 10000
        }).then(function(r){
            g.loading = false
            if(r.status != 100){
                alert(r.response)
            }else{
                g.students = r.response
            }
        }).fail(function(){
            g.loading = false;
        })
    },
    data(){
        return {
            student_: {name: "", number: ""},
            user: JSON.parse(localStorage.getItem('user')),
            students: []
        }
    },
    methods: {
        callNow(){
            var g = this;
            $.post(api + '/user/calls/call', {
                jwt: this.user.jwt,
                number: this.student_.number
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    alert("تم بنجاح", 100)
                }
            }).fail(function(){
                alert("حدث خطأ", 200)
            })
        }
    }
}
</script>

<style>
@import url(https://cdnjs.cloudflare.com/ajax/libs/vue-select/3.10.0/vue-select.css);
</style>